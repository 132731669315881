import React from "react"
import { slide as Menu } from "react-burger-menu"
import { Link } from "gatsby"

export default function Sidebar(props) {
  return (
    <Menu
        disableAutoFocus
    >
      <Link to="/">Home</Link>
      <Link to="/about/">About</Link>
      <Link to="/services/">Services</Link>
      <Link to="/approach/">Approach</Link>
      <Link to="/fees/">Fees / Rebates</Link>
      <Link to="/faqs/">FAQs</Link>
      <Link to="/contact/">Contact</Link>
      <Link to="/book/">Book appointment</Link>
    </Menu>
  )
}
