import React from "react"
import { Link } from 'gatsby'

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-left">
        <p>
          <a href="mailto:sarah@hartleyclinicalpsychology.com" target="_blank">
            sarah@hartleyclinicalpsychology.com
          </a>
        </p>
      </div>

      {/* <div className="footer-centre">
        <p>
          <Link to="/privacy">Privacy</Link>
        </p>
      </div> */}

      <div className="footer-right">
        <p>
          <a href="tel:+61 476 940 998" target="_blank">
            0476 940 998
          </a>
        </p>
      </div>
    </div>
  )
}
