import React from "react"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"
import "../styles/styles.scss"
import ogImage from '../images/colorful-sunset-sunrise-dramatic-sky-above-wooden--FLSXCYZ.jpg'


export default ({ children, hideFooter = false }) => (
  <>
    <Helmet>
      <script
        src="https://kit.fontawesome.com/ac34cea347.js"
        crossOrigin="anonymous"
        type="text/javascript"
      />
      <meta
        name="description"
        content="Clinical Psychology services in Perth - Individual Therapy, Couples Therapy and Clinical Supervision"
      />
      <meta
        name="keywords"
        content="Clinical, Psychology, Therapy, Couples, Relationship, Supervision"
      />
      <meta property="og:url" content="https://hartleyclinicalpsychology.com" />
      <meta property="og:title" content="Hartley Clinical Psychology" />
      <meta
        property="og:description"
        content="Clinical Psychology services in Perth - Individual Therapy, Couples Therapy and Clinical Supervision"
      />
      {/* <meta property="og:image" content={`${process.env.SITE_URL}/og-image`} /> */}
      <meta property="og:image" content={ process.env.SITE_URL + ogImage } />
      <title>Hartley Clinical Psychology</title>
    </Helmet>

    <div id="outer-container">
      <div id="page-wrap">
        <Navigation />
        <div>{children}</div>
        <div className="flex-fill"></div>
        {hideFooter || <Footer />}
      </div>
    </div>
  </>
)
