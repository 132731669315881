import React, { useEffect } from "react"
import { Link } from "gatsby"
import Sidebar from "./Sidebar"

export default () => {
  // TODO - make a decision between the different slideout libraries
  // TODO - remove the unwanted ones.

  return (
    <>
      <div className="mobile-nav">
        <Sidebar />
      </div>
      <nav className="navigation">
        <ul>
          <li>
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about/" className="nav-link">
              About
            </Link>
          </li>
          <li>
            <Link to="/services/" className="nav-link">
              Services
            </Link>
          </li>
          <li>
            <Link to="/approach/" className="nav-link">
              Approach
            </Link>
          </li>

          <li>
            <Link to="/fees/" className="nav-link">
              Fees / Rebates
            </Link>
          </li>
          <li>
            <Link to="/faqs/" className="nav-link">
              FAQs
            </Link>
          </li>
          <li>
            <Link to="/contact/" className="nav-link">
              Contact
            </Link>
          </li>
        </ul>

        <Link to="/book">
          <div className="btn">Book an appointment</div>
        </Link>
      </nav>
    </>
  )
}
